import * as React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Link, navigate } from 'gatsby';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
} from 'react-hook-form';
import { Text, useToast } from '@chakra-ui/react';

import CustomButton from './CustomButton';
import { loadingState } from '../atoms/PageAtom';
import { loginService } from '../services';
import InputEmailController from './InputEmailController';
import InputPasswordController from './InputPasswordController';
import { cartState } from '../atoms/CartAtom';

const FormLogin = () => {
  const { control, formState, handleSubmit, getValues, setValue } = useForm();
  const setIsLoading = useSetRecoilState(loadingState);
  const [_, setCart] = useRecoilState(cartState);
  const toast = useToast();

  const onValid: SubmitHandler<any> = async values => {
    const userData = {
      username: values.email,
      password: values.password,
    };
    try {
      setIsLoading(true);
      if (!await loginService(userData)) {
        navigate('https://' + process.env.GATSBY_TOLETTA_SITE_DOMAIN);
      } else {
        setCart([]);
        navigate('/app/');
      }
    } catch (err) {
      console.log(err);
      toast({
        title: <Text fontSize="md">ログインに失敗しました</Text>,
        description: (
          <Text fontSize="sm">
            メールアドレスとパスワードをご確認ください。解決しない場合はお問い合わせください。
          </Text>
        ),
        status: 'error',
        duration: 8000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onInvalid: SubmitErrorHandler<FieldValues> = err => {
    console.log(err);
  };

  return (
    <form onSubmit={handleSubmit(onValid, onInvalid)}>
      <InputEmailController
        id="email"
        control={control}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
        showHelperText={false}
        isRequired={false}
      />
      <InputPasswordController
        id="password"
        control={control}
        formState={formState}
        getValues={getValues}
        showHelperText={false}
        isRequired={false}
        isValidationRequired={false}
      />
      <Text fontSize="xs" color="brand.subtitle" mt={4} mb={4}>
        パスワードを忘れた方は
        <Link to="/reset-password">
          <Text as="span" color="brand.pink">
            <u>こちら</u>
          </Text>
        </Link>
      </Text>
      <CustomButton color="white" bg="brand.pink" type="submit">
        ログイン
      </CustomButton>
    </form>
  );
};

export default FormLogin;
