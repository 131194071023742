import * as React from 'react';
import Layout from '../components/Layout';
import PageInitThanks from '../features/PageInitThanks';

type PageProps = {
  location: Location;
};

const InitThanks = ({ location }: PageProps) => {
  return (
    <Layout location={location}>
      <PageInitThanks location={location} />
    </Layout>
  );
};

export default InitThanks;
