import * as PhoneLib from 'google-libphonenumber';

const banWords = {
  ja: [
    '髙',
    '﨑',
    '栁',
    '桺',
    '㐂',
    '圡',
    '𡈽',
    '𠮷',
    '槗',
    '𣘺',
    '曻',
    '濵',
    '〝',
    '〟',
    'ℓ',
    '逸',
    '謁',
    '緣',
    '焰',
    '橫',
    '鷗',
    '溫',
    '禍',
    '悔',
    '海',
    '慨',
    '槪',
    '喝',
    '渴',
    '褐',
    '寬',
    '漢',
    '滊',
    '祈',
    '既',
    '器',
    '虛',
    '鄕',
    '響',
    '勤',
    '謹',
    '薰',
    '揭',
    '擊',
    '硏',
    '黃',
    '穀',
    '黑',
    '殺',
    '祉',
    '視',
    '社',
    '者',
    '煮',
    '臭',
    '祝',
    '暑',
    '署',
    '緖',
    '諸',
    '涉',
    '祥',
    '狀',
    '神',
    '瀨',
    '節',
    '祖',
    '巢',
    '僧',
    '層',
    '增',
    '憎',
    '贈',
    '卽',
    '嘆',
    '著',
    '徵',
    '懲',
    '塚',
    '顚',
    '都',
    '德',
    '突',
    '難',
    '梅',
    '繁',
    '晚',
    '卑',
    '碑',
    '賓',
    '頻',
    '敏',
    '甁',
    '侮',
    '福',
    '倂',
    '塀',
    '勉',
    '步',
    '墨',
    '每',
    '免',
    '麵',
    '祐',
    '賴',
    '欄',
    '隆',
    '虜',
    '綠',
    '淚',
    '類',
    '戾',
    '曆',
    '歷',
    '練',
    '鍊',
    '郞',
    '朗',
    '廊',
    '錄',
    '麴',
    '撿',
    '嚙',
    '飧',
    '玆',
    '渚',
    '琢',
    '猪',
    '禎',
    '屢',
    '﨟',
    '鄧',
    '∸',
    '∹',
    '∺',
    '⊖',
    '⊝',
    '⊟',
    '⊶',
    '⊷',
    '⊸',
    '⋯',
    '—',
    '₋',
  ],
};

export const isValidPassword = (password: string): boolean => {
  if (password.length < 6) return false;
  const pattern = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{6,100}$/i;
  return !!password.match(pattern);
};

export const includeBanWords = (word: string) => {
  const banWordsList = banWords['ja'];
  if (!word) return false;
  if (banWordsList.some(banWord => word.includes(banWord))) {
    return true;
  }
  return false;
};

export const isPhone = (phone: string) => {
  const phoneUtil = PhoneLib.PhoneNumberUtil.getInstance();
  return phoneUtil.isValidNumberForRegion(phoneUtil.parse(phone, 'JP'), 'JP');
};
