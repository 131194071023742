import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Text, Divider, Box } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Title from '../components/Title/Title';
import { useRecoilState } from 'recoil';
import { cartState } from '../atoms/CartAtom';
import {
  calcLineItemsPrice,
  calcShippingPrice,
  calcTotalTax,
} from '../utils/calc';
import { CheckoutData } from '../types';
import PlanBoard from '../components/PlanBoard';
import { loadingState } from '../atoms/PageAtom';
import { getInventoryQuantityService, getUserService } from '../services';
import PriceBoard from '../components/PriceBoard';
import { storefrontId as productId } from '../assets/products';
import CheckoutAddressConfirm from '../features/CheckoutAddressConfirm';
import CheckoutQuantityChangeModal from '../features/CheckoutQuantityChangeModal';
import FormCheckout from '../components/FormCheckout';
import Stepper from '../components/Stepper';
import { userDataState } from '../atoms/UserAtom';

const query = graphql`
  fragment ProductTileFields on ShopifyProduct {
    handle
    priceRangeV2 {
      minVariantPrice {
        amount
      }
    }
  }
  query ShopifyTolettaCheckoutProducts {
    shopifyMainStandardPlanProducts: allShopifyCollection(
      filter: { title: { eq: "WebMainStandardPlanProducts" } }
    ) {
      nodes {
        title
        products {
          ...ShopifyProductFields
          ...ProductTileFields
        }
      }
    }
  }
`;

type PageProps = {
  location: Location;
};

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_PUBLISHABLE_KEY as string
);

const PageInitCheckout = ({ location }: PageProps) => {
  const [cart, setCart] = useRecoilState(cartState);
  const [_, setUserData] = useRecoilState(userDataState);
  const [__, setIsLoading] = useRecoilState(loadingState);

  const queriedProducts = useStaticQuery(query);
  const { shopifyMainStandardPlanProducts } = queriedProducts;
  const queriedProduct =
    shopifyMainStandardPlanProducts.nodes?.[0].products?.[0];
  const title = queriedProduct?.title as string;
  const price = queriedProduct?.priceRangeV2?.minVariantPrice?.amount as string;
  const storefrontId = queriedProduct?.variants?.[0]?.storefrontId as string;

  let order: CheckoutData = {
    line_items: cart.map(i => {
      return {
        variant_id: i.storefrontId,
        name: i.name,
        price: i.price,
        quantity: i.quantity,
      };
    }),
    total_tax: calcTotalTax(cart).toString(),
    total_line_items_price: calcLineItemsPrice(cart).toString(),
    total_price: (calcTotalTax(cart) + calcLineItemsPrice(cart)).toString(),
    total_shipping_price_set: {
      presentment_money: {
        amount: calcShippingPrice(cart).toString(),
        currenty_code: 'JP',
      },
    },
  };

  // リロードしているとカートがクリアされるので補充
  React.useEffect(() => {
    let abortCtrl = new AbortController();
    async function init() {
      try {
        setIsLoading(true);
        let res = await getInventoryQuantityService(storefrontId);
        let user = await getUserService();
        setUserData(user);
        const existsInventoryQuantity = res.data.inventory_quantity;
        // もしも在庫が0ならば終了
        if (existsInventoryQuantity === 0) return;
        // 在庫があればトレッタ選択台数の初期値を1にする
        const existItem = cart.find(i => i.storefrontId === storefrontId);
        if (existItem) {
          return;
        } else {
          setCart(old => [
            ...old.filter(i => i.storefrontId !== storefrontId),
            { storefrontId, quantity: 1, name: title, price },
          ]);
        }
        console.log(cart);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    init();
    return () => {
      abortCtrl.abort();
    };
  }, []);

  return (
    <Layout location={location}>
      <Seo title="お支払い情報の入力" />
      <Stepper activeIndex={1} />
      <Title>お支払い情報の入力</Title>
      {order.line_items.find(item => item.variant_id === productId.toletta) ||
      order.line_items.find(
        item => item.variant_id === productId.tolettaBaseToletta
      ) ? (
        <Text mt={4} mb={2} fontSize="sm">
          プランご契約内容
        </Text>
      ) : null}
      <PlanBoard order={order} />
      <Text mt={4} mb={2} fontSize="sm">
        ご購入品内容
      </Text>
      <Box textAlign="right" mb={2}>
        <CheckoutQuantityChangeModal queriedProduct={queriedProduct} />
      </Box>
      <PriceBoard order={order} />
      <Divider my={4} />
      <CheckoutAddressConfirm isInitFlow={true} />
      <Elements stripe={stripePromise}>
        <FormCheckout isInitialCheckout={true} />
      </Elements>
    </Layout>
  );
};

export default PageInitCheckout;
