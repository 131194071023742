import * as React from 'react';
import { navigate } from 'gatsby';
import { Box } from '@chakra-ui/react';

const IndexPage = () => {
  // 新規購入導線は閉じるためログインページに遷移させる
  if (typeof window !== `undefined`) {
    navigate('/login/');
  }
  return <Box></Box>;
};

export default IndexPage;
