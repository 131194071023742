import * as React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useRecoilState } from 'recoil';
import { cartState } from '../atoms/CartAtom';
import { Divider, Text } from '@chakra-ui/react';

import { CheckoutData, OrderData } from '../types/index';
import {
  calcLineItemsPrice,
  calcShippingPrice,
  calcTotalTax,
} from '../utils/calc';
import Title from '../components/Title/Title';
import Seo from '../components/Seo';
import PriceBoard from '../components/PriceBoard';
import CheckoutAddressConfirm from './CheckoutAddressConfirm';
import PlanBoard from '../components/PlanBoard';
import { Link } from 'gatsby';
import FormCheckout from '../components/FormCheckout';
import { loadingState } from '../atoms/PageAtom';
import { getOrderHistoryService, getUserService } from '../services';
import { userDataState } from '../atoms/UserAtom';
import { isStandardPlan, isTolettaPlan } from '../repository/tolettaApi';

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_PUBLISHABLE_KEY as string
);

const PageCheckout = () => {
  const [cart, _] = useRecoilState(cartState);
  const [__, setIsLoading] = useRecoilState(loadingState);
  const [___, setUserData] = useRecoilState(userDataState);
  const [____, setOrderList] = React.useState<OrderData[] | null>(null);

  let order: CheckoutData = {
    line_items: cart.map(i => {
      return {
        variant_id: i.storefrontId,
        name: i.name,
        price: i.price,
        quantity: i.quantity,
      };
    }),
    total_tax: calcTotalTax(cart).toString(),
    total_line_items_price: calcLineItemsPrice(cart).toString(),
    total_price: (
      calcTotalTax(cart) +
      calcLineItemsPrice(cart) +
      calcShippingPrice(cart)
    ).toString(),
    total_shipping_price_set: {
      presentment_money: {
        amount: calcShippingPrice(cart).toString(),
        currenty_code: 'JP',
      },
    },
  };

  React.useEffect(() => {
    let abortCtrl = new AbortController();
    async function init() {
      try {
        setIsLoading(true);
        let orderRes = await getOrderHistoryService();
        let user = await getUserService();
        setUserData(user);
        setOrderList(orderRes.data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    init();
    return () => {
      abortCtrl.abort();
    };
  }, []);

  return (
    <>
      <Seo title="お支払い内容のご確認" />
      <Title>お支払い内容のご確認</Title>
      {order.line_items.length > 0 ? (
        <>
          {isTolettaPlan() || isStandardPlan()
           ? (
            <Text mt={4} mb={2} fontSize="sm">
              プランご契約内容
            </Text>
          ) : null}
          <PlanBoard order={order} />
          <Text mt={4} mb={2} fontSize="sm">
            ご購入品内容
          </Text>
          <PriceBoard order={order} />
          <Divider my={4} />
          <CheckoutAddressConfirm />
          <Elements stripe={stripePromise}>
            <FormCheckout isInitialCheckout={false} />
          </Elements>
        </>
      ) : (
        <Text>
          商品が選択されていません。商品一覧は
          <Text as="span" color="brand.pink">
            <Link to="/products">
              <u>こちら</u>
            </Link>
          </Text>
        </Text>
      )}
    </>
  );
};

export default PageCheckout;
