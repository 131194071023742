import * as React from 'react';
import { Flex, Box, Text, Skeleton } from '@chakra-ui/react';
import { CheckoutData, OrderData } from '../types';
import { useRecoilState } from 'recoil';
import { loadingState } from '../atoms/PageAtom';
import { getLineItemPrice, getTotalLineItemsPrice, getTotalPrice, getTotalTax } from '../assets/products';

type PriceBoardProps = {
  order: OrderData | CheckoutData;
  adjustQty?: boolean;
};

const PriceBoard = ({ order }: PriceBoardProps) => {
  const { line_items } = order;
  const [isLoading, _] = useRecoilState(loadingState);

  return (
    <Box boxShadow="md" p={2} borderRadius="md">
      <Flex fontSize="xs" fontWeight={600} p={2} my={2}>
        <Text flex="3">商品名</Text>
        <Text flex="1" textAlign="right">
          数量
        </Text>
        <Text flex="2" textAlign="right">
          価格
        </Text>
      </Flex>
      {line_items.map(lineItem => (
        <Skeleton isLoaded={!isLoading} key={lineItem.variant_id}>
          <Flex
            gridGap="4"
            fontSize="xs"
            p={2}
            my={2}
            bg="brand.background"
            borderRadius={4}
          >
            <Text flex="3">{lineItem.name}</Text>
            <Box
              flex="1"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Text>{lineItem.quantity}</Text>
            </Box>
            <Text
              flex="2"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              ¥{(getLineItemPrice(lineItem) * lineItem.quantity).toLocaleString()}
            </Text>
          </Flex>
        </Skeleton>
      ))}
      <Flex
        borderBottom="1px solid #F4F2EE"
        color="brand.subtitle"
        fontSize="xs"
        mt={4}
        p={2}
        justify="space-between"
      >
        <Text>小計</Text>
        <Text>¥{getTotalLineItemsPrice(order).toLocaleString()}</Text>
      </Flex>
      <Flex
        borderBottom="1px solid #F4F2EE"
        color="brand.subtitle"
        fontSize="xs"
        mt={4}
        p={2}
        justify="space-between"
      >
        <Text>税</Text>
        <Text>¥{Math.round(getTotalTax(order)).toLocaleString()}</Text>
      </Flex>
      <Flex
        borderBottom="1px solid #F4F2EE"
        color="brand.subtitle"
        fontSize="xs"
        mt={4}
        p={2}
        justify="space-between"
      >
        <Text>送料</Text>
        <Text>
          ¥
          {Number(
            order.total_shipping_price_set.presentment_money.amount
          ).toLocaleString()}
        </Text>
      </Flex>
      <Flex fontSize="xs" fontWeight={600} mt={4} p={2} justify="space-between">
        <Text>合計</Text>
        <Text>¥{Math.round(getTotalPrice(order)).toLocaleString()}</Text>
      </Flex>
    </Box>
  );
};

export default PriceBoard;
