import * as React from 'react';
import {
  Box,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import CustomButton from './CustomButton';

type CustomAlertDialogProps = {
  buttonText: string;
  title: string;
  children: React.ReactNode;
  okText: string;
  cancelText: string;
  submit: () => {};
};

const CustomAlertDialog = ({
  buttonText,
  title,
  children,
  okText,
  cancelText,
  submit,
}: CustomAlertDialogProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <Box my={4}>
      <CustomButton
        onClick={() => setIsOpen(true)}
        color="white"
        bg="brand.pink"
      >
        {buttonText}
      </CustomButton>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent w="90%" my="auto">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{children}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {cancelText}
              </Button>
              <Button
                color="white"
                bg="brand.pink"
                onClick={() => {
                  submit();
                  setIsOpen(false);
                }}
                ml={3}
              >
                {okText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default CustomAlertDialog;
