import * as React from "react";
import { ButtonProps } from "@chakra-ui/react";

import CustomButton from "./CustomButton";

const PrimaryButton: React.FC<ButtonProps> = props => {
  return <CustomButton color="white" bg="brand.pink" {...props} />;
};

export default PrimaryButton;
