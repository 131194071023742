import * as React from 'react';
import {
  Flex,
  IconButton,
  Text,
  Alert,
  AlertTitle,
  AlertIcon,
  Skeleton,
  useToast,
} from '@chakra-ui/react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useRecoilState } from 'recoil';
import { cartState } from '../atoms/CartAtom';
import { getInventoryQuantityService } from '../services';
import { maxOrderQuantity } from '../assets/products';

type QuantityAdjusterButtonProps = {
  storefrontId: string;
  name: string;
  price: string;
  min?: number;
  showToast?: boolean;
};

const QuantityAdjusterButton = ({
  storefrontId,
  name,
  price,
  min = 0,
  showToast = true,
}: QuantityAdjusterButtonProps) => {
  const toast = useToast();
  const addToastId = 'ADD_ITEM';
  const removeToastId = 'REMOVE_ITEM';
  const [cart, setCart] = useRecoilState(cartState);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [inventoryQuantity, setInventoryQuantity] = React.useState<
    number | null
  >(null);

  const currentQuantity =
    cart.find(c => c.storefrontId === storefrontId)?.quantity || 0;

  function addItem() {
    const existItem = cart.find(i => i.storefrontId === storefrontId);
    if (existItem) {
      const newItem = {
        storefrontId,
        quantity: currentQuantity + 1,
        name,
        price,
      };
      setCart(old => [
        ...old.filter(i => i.storefrontId !== storefrontId),
        newItem,
      ]);
    } else {
      setCart(old => [
        ...old.filter(i => i.storefrontId !== storefrontId),
        { storefrontId, quantity: 1, name, price },
      ]);
    }
    if (showToast && !toast.isActive(addToastId)) {
      toast({
        id: addToastId,
        duration: 2000,
        title: <Text fontSize="md">商品を追加しました</Text>,
        description: <Text fontSize="xs">カートをご確認ください</Text>,
        status: 'success',
        isClosable: true,
      });
    }
  }

  function removeItem() {
    const existItem = cart.find(i => i.storefrontId === storefrontId);
    if (existItem && existItem.quantity > 1) {
      const newItem = {
        storefrontId,
        quantity: currentQuantity - 1,
        name,
        price,
      };
      setCart(old => [
        ...old.filter(i => i.storefrontId !== storefrontId),
        newItem,
      ]);
    } else if (existItem && existItem.quantity === 1) {
      setCart(old => [...old.filter(i => i.storefrontId !== storefrontId)]);
    } else return;
    if (showToast && !toast.isActive(removeToastId)) {
      toast({
        id: removeToastId,
        duration: 2000,
        title: <Text fontSize="md">商品を削除しました</Text>,
        description: <Text fontSize="xs">カートをご確認ください</Text>,
        status: 'error',
        isClosable: true,
      });
    }
  }

  React.useEffect(() => {
    let abortCtrl = new AbortController();
    async function init() {
      try {
        setLoading(true);
        let res = await getInventoryQuantityService(storefrontId);
        setInventoryQuantity(res.data.inventory_quantity);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    init();
    return () => {
      abortCtrl.abort();
    };
  }, []);

  return (
    <>
      <Flex justify="space-around" alignItems="center" mt={4}>
        <IconButton
          aria-label="minus"
          icon={<AiOutlineMinus />}
          w="55px"
          rounded="34px"
          color="white"
          bg="brand.pink"
          _hover={{ bg: 'brand.pink' }}
          onClick={removeItem}
          disabled={currentQuantity === 0 || currentQuantity <= min || loading}
        />
        <Skeleton isLoaded={!loading}>
          <Text fontSize={36}>{currentQuantity}</Text>
        </Skeleton>
        <IconButton
          aria-label="plus"
          icon={<AiOutlinePlus />}
          w="55px"
          rounded="34px"
          color="white"
          bg="brand.pink"
          onClick={addItem}
          _hover={{ bg: 'brand.pink' }}
          disabled={
            inventoryQuantity === null ||
            inventoryQuantity <= currentQuantity ||
            maxOrderQuantity <= currentQuantity ||
            loading
          }
        />
      </Flex>
      {!loading &&
      inventoryQuantity !== null &&
      inventoryQuantity <= currentQuantity ? (
        <Alert
          status="error"
          mt={4}
          rounded="md"
          display="flex"
          justifyContent="flex-start"
        >
          <AlertIcon />
          <AlertTitle mr={2}>
            <Text>在庫上限に達したためこれ以上選択できません</Text>
          </AlertTitle>
        </Alert>
      ) : null}
      {!loading && maxOrderQuantity <= currentQuantity ? (
        <Alert
          status="error"
          mt={4}
          rounded="md"
          display="flex"
          justifyContent="flex-start"
        >
          <AlertIcon />
          <AlertTitle mr={2}>
            <Text>一度に注文できる数の上限に達しました</Text>
          </AlertTitle>
        </Alert>
      ) : null}
    </>
  );
};

export default QuantityAdjusterButton;
