import { selector } from "recoil";
import { loadingState, reloadState } from "../atoms/PageAtom";

export const loadingSelector = selector<boolean>({
  key: "loadingSelector",
  get: ({ get }) => {
    return get(loadingState);
  },
});

export const reloadSelector = selector<boolean>({
  key: "reloadSelector",
  get: ({ get }) => {
    return get(reloadState);
  },
});
