import * as React from 'react';
import { navigate } from 'gatsby';
import { Box } from '@chakra-ui/react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Title from '../components/Title/Title';
import FormLogin from '../components/FormLogin';
import { isLoggedIn } from '../repository/tolettaApi';

type PageProps = {
  location: Location;
};

const LoginPage = ({ location }: PageProps) => {
  if (isLoggedIn()) {
    navigate('/app/');
    return <Box></Box>;
  }
  return (
    <Layout location={location}>
      <Seo title="ログイン" />
      <Title>ログイン</Title>
      <FormLogin />
    </Layout>
  );
};

export default LoginPage;
