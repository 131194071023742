import * as React from 'react';
import {
  chakra,
  Box,
  Flex,
  Text,
  Skeleton,
  Spacer,
  Divider,
  useToast,
} from '@chakra-ui/react';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { StaticImage } from 'gatsby-plugin-image';
import CCFormWrapper from './CCFormWrapper';
import PrimaryButton from './PrimaryButton';
import { useRecoilState } from 'recoil';
import { loadingState, reloadState } from '../atoms/PageAtom';
import { changeCCService, getCardExpire, getCardInfoService } from '../services';
import { ShowCCData } from '../types';
import { toastErrors } from '../utils/errors';

const FormChangeCC = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const [reload, setReload] = useRecoilState(reloadState);
  const [cc, setCc] = React.useState<ShowCCData | null>(null);

  const stripe = useStripe();
  const elements = useElements();

  const [brand, setBrand] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);
  const [errorCardNumber, setErrorCardNumber] = React.useState(true);
  const [errorCardExpiry, setErrorCardExpiry] = React.useState(true);
  const [errorCardCvc, setErrorCardCvc] = React.useState(true);

  const handleCardNumberChange = (
    event: StripeCardNumberElementChangeEvent
  ) => {
    // カード番号のチェックを行う
    setBrand(event.brand || '');
    if (event.complete && !event.error && elements) {
      // 入力完了してエラーが無い場合
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      cardExpiryElement?.focus();
      setErrorCardNumber(false);
    } else {
      setErrorCardNumber(true);
    }
  };

  const handleCardExpiryChange = (
    event: StripeCardExpiryElementChangeEvent
  ) => {
    // 有効期限のチェックを行う
    if (event.complete && !event.error && elements) {
      // 入力完了してエラーが無い場合
      const cardCvcElement = elements.getElement(CardCvcElement);
      cardCvcElement?.focus();
      setErrorCardExpiry(false);
    } else {
      setErrorCardExpiry(true);
    }
  };

  const handleCardCvcChange = (
    event: StripeCardCvcElementChangeEvent
  ) => {
    // CVCのチェックを行う
    if (event.complete && !event.error && elements) {
      // 入力完了してエラーが無い場合
      setErrorCardCvc(false);
    } else {
      setErrorCardCvc(true);
    }
  };

  React.useEffect(() => {
    setDisabled(errorCardNumber || errorCardExpiry || errorCardCvc);
  }, [errorCardNumber, errorCardExpiry, errorCardCvc]);

  const isYourBrand = (brandName: string) => {
    return brandName === brand;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setIsLoading(true);
    let isCreateTokenError = false;
    try {
      if (!stripe || !elements || !cc) {
        throw new Error('エラーが発生しました。時間をおいてお試しください。');
      }

      let stripeTokenId;

      const cardNumberElement = elements.getElement(CardNumberElement);
      if (!cardNumberElement) {
        throw new Error(
          'カード番号が見つかりません。時間をおいてお試しください。'
        );
      }

      let stripeTokenRes = await stripe.createToken(cardNumberElement);
      if (!stripeTokenRes.token) {
        isCreateTokenError = true;
        throw new Error(
          `code: ${stripeTokenRes.error.code}, type: ${stripeTokenRes.error.type}, message: ${stripeTokenRes.error.message}`
        );
      }

      stripeTokenId = stripeTokenRes.token?.id;

      const errorMessage = await changeCCService(cc.cardId, stripeTokenId);
      if (errorMessage) {
        toastErrors('', toast, 'エラーが発生しました', errorMessage);
      } else {
        toast({
          title: <Text fontSize="md">カード情報を更新しました</Text>,
          status: 'success',
          isClosable: true,
        });
        setReload(reload => !reload);
      }
    } catch (err) {
      isCreateTokenError ? toastErrors(err, toast, 'エラーが発生しました', 'カード番号をご確認ください。') : toastErrors(err, toast);
    } finally {
      setIsLoading(false);
      setErrorCardNumber(true);
      setErrorCardExpiry(true);
      setErrorCardCvc(true);
    }
  };

  React.useEffect(() => {
    let abortCtrl = new AbortController();
    async function init() {
      try {
        setIsLoading(true);
        let card = await getCardInfoService();
        setCc(card);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    init();
    return () => {
      abortCtrl.abort();
    };
  }, [reload]);

  return (
    <>
      <Skeleton isLoaded={!isLoading}>
        {cc ? (
          <Text fontSize="sm" my={4}>
            現在登録中のカード情報
          </Text>
        ) : (
          <Text fontSize="sm" my={4}>
            カード情報が登録されていません。
            <br />
            商品を購入するとカード情報が登録されます。
          </Text>
        )}
      </Skeleton>
      <Skeleton isLoaded={!isLoading}>
        {cc ? (
          <>
            <Flex alignItems="center" justify="space-between">
              <Box>
                {cc.brand.toLowerCase() === 'visa' ? (
                  <StaticImage src="../images/visa.svg" alt="visa" />
                ) : cc.brand.toLowerCase() === 'mastercard' ? (
                  <StaticImage
                    src="../images/mastercard.svg"
                    alt="mastercard"
                  />
                ) : cc.brand.toLowerCase() === 'amex' ||
                  cc.brand.toLowerCase() === 'american express' ? (
                  <StaticImage src="../images/amex.svg" alt="amex" />
                ) : cc.brand.toLowerCase() === 'jcb' ? (
                  <StaticImage src="../images/jcb.svg" alt="jcb" />
                ) : (
                  <Text>{cc.brand}</Text>
                )}
              </Box>
              <Text p={4} fontSize="md">
                {`末尾${cc.last4}`}
              </Text>
              <Spacer />
              <Text p={4} fontSize="md">
                有効期限{getCardExpire(cc.expire)}
              </Text>
            </Flex>
            <Divider />
            <Skeleton isLoaded={!isLoading}>
              <Text fontSize="sm" my={4}>
                変更するカード情報を入力してください
              </Text>
            </Skeleton>
            <chakra.form my={4} onSubmit={handleSubmit}>
              <Text as="label" htmlFor="cardNumber">
                カード番号
              </Text>
              <CCFormWrapper>
                <CardNumberElement
                  id="cardNumber"
                  onChange={handleCardNumberChange}
                />
              </CCFormWrapper>
              <Flex my={4} justify="space-around">
                <Box opacity={isYourBrand('visa') ? 1 : 0.4}>
                  <StaticImage src="../images/visa.svg" alt="visa" />
                </Box>
                <Box opacity={isYourBrand('mastercard') ? 1 : 0.4}>
                  <StaticImage
                    src="../images/mastercard.svg"
                    alt="mastercard"
                  />
                </Box>
                <Box opacity={isYourBrand('amex') ? 1 : 0.4}>
                  <StaticImage src="../images/amex.svg" alt="amex" />
                </Box>
                <Box opacity={isYourBrand('jcb') ? 1 : 0.4}>
                  <StaticImage src="../images/jcb.svg" alt="jcb" />
                </Box>
              </Flex>
              <Flex justify="space-between">
                <Box w="49%">
                  <Text as="label" htmlFor="cardExpiry">
                    有効期限
                  </Text>
                  <CCFormWrapper>
                    <CardExpiryElement
                      id="cardExpiry"
                      onChange={handleCardExpiryChange}
                    />
                  </CCFormWrapper>
                </Box>
                <Box w="49%">
                  <Text as="label" htmlFor="cardCvc">
                    セキュリティコード
                  </Text>
                  <CCFormWrapper>
                    <CardCvcElement id="cardCvc" onChange={handleCardCvcChange} />
                  </CCFormWrapper>
                </Box>
              </Flex>
              <Text fontSize="xs" my={1} color="brand.subtitle">
                ※プラン料金のお支払いはクレジットカード(VISA,MASTER,AMEX,JCB)、
                またはデビットカード(三井住友銀行のVISAデビットカードを除く)のみご利用いただけます。
              </Text>
              <Text fontSize="xs" mt={1} mb={8} color="brand.subtitle">
                ※広告ブロック系の拡張機能をご利用の方は情報を入力できない場合がございます。
                一時的に無効化するようお願いいたします。
              </Text>
              <PrimaryButton
                color="white"
                bg="brand.pink"
                ml={1}
                type="submit"
                disabled={disabled}
              >
                確定
              </PrimaryButton>
            </chakra.form>
          </>
        ) : null}
      </Skeleton>
    </>
  );
};

export default FormChangeCC;
