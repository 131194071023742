import * as React from 'react';

import ProductsGrid from './ProductsGrid';
import {
  isStandardPlan,
  isTolettaPlan,
} from '../repository/tolettaApi';

type ProductTabsProps = {
  queriedProducts: GatsbyTypes.ShopifyProductsQuery;
};

const ProductTabs = ({ queriedProducts }: ProductTabsProps) => {
  const {
    mainStandardPlanProducts,
    mainCatBaseProducts,
    mainTolettaBaseProducts,
  } = queriedProducts;

  return (
    <>
      <ProductsGrid
        products={
          isStandardPlan()
            ? mainStandardPlanProducts.nodes?.[0].products
            : isTolettaPlan()
            ? mainTolettaBaseProducts.nodes?.[0].products
            : mainCatBaseProducts.nodes?.[0].products
        }
      />
    </>
  );
};

export default ProductTabs;
