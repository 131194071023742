import React from "react"
import { RecoilRoot } from "recoil"

export const wrapRootElement = ({ element, props }) => {
  return <RecoilRoot {...props}>{element}</RecoilRoot>
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `アプリケーションの内容が更新されました。` +
      `ページを更新して新しい内容を表示しますか？`
  )

  if (answer === true) {
    window.location.reload()
  }
}