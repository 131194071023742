import * as React from 'react';
import { Controller, FieldValues, Control } from 'react-hook-form';
import { FormControl, Checkbox, Flex, Text, Box } from '@chakra-ui/react';

type InputAcceptsMarketingControllerProps = {
  id: string;
  control: Control<FieldValues, object>;
  label?: string;
};

const InputAcceptsMarketingController = ({
  id,
  control,
}: InputAcceptsMarketingControllerProps) => {
  return (
    <Flex justify="space-between" my={8}>
      <Box>
        <Text fontSize="sm">トレッタからのお知らせを受け取る</Text>
        <Text fontSize="xs" color="brand.subtitle" mt={1}>
          ※新製品の情報などをメールでお届けします
        </Text>
      </Box>
      <Box>
        <Controller
          name={id}
          control={control}
          render={({ field }) => (
            <FormControl {...field}>
              <Checkbox
                id={id}
                size="lg"
                isChecked={field.value}
                onChange={field.onChange}
                name={id}
                colorScheme="brand"
              />
            </FormControl>
          )}
        />
      </Box>
    </Flex>
  );
};

export default InputAcceptsMarketingController;
