import * as React from 'react';
import { useSetRecoilState } from 'recoil';
import { useToast, Text } from '@chakra-ui/react';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
} from 'react-hook-form';

import { loadingState } from '../atoms/PageAtom';
import CustomButton from './CustomButton';
import { resetPasswordService } from '../services';
import InputEmailController from './InputEmailController';
import { toastErrors } from '../utils/errors';

const FormResetPassword = () => {
  const { control, formState, handleSubmit, getValues, setValue } = useForm();
  const setIsLoading = useSetRecoilState(loadingState);
  const toast = useToast();

  const onValid: SubmitHandler<any> = async values => {
    try {
      setIsLoading(true);
      let res = await resetPasswordService(values.email);
      console.log(res);
      toast({
        title: <Text fontSize="md">パスワードの再発行が完了しました</Text>,
        description: (
          <Text fontSize="sm">
            メールをご確認ください。メールが届かない場合はお問い合わせください。
          </Text>
        ),
        status: 'success',
        isClosable: true,
      });
    } catch (err) {
      toastErrors(err, toast);
    } finally {
      setIsLoading(false);
    }
  };

  const onInvalid: SubmitErrorHandler<FieldValues> = err => {
    console.log(err);
  };
  return (
    <form onSubmit={handleSubmit(onValid, onInvalid)}>
      <InputEmailController
        id="email"
        control={control}
        formState={formState}
        label="ご利用中のメールアドレス"
        getValues={getValues}
        setValue={setValue}
      />
      <CustomButton color="white" bg="brand.pink" type="submit">
        再発行する
      </CustomButton>
    </form>
  );
};

export default FormResetPassword;
