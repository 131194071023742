import * as React from 'react';
import { Text, useToast } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';

import Title from '../components/Title/Title';
import Seo from '../components/Seo';
import ProductTabs from './ProductTabs';
import ToCartButton from '../components/ToCartButton';
import { resetPlan } from '../services';

const query = graphql`
  fragment ProductTileFields on ShopifyProduct {
    handle
    priceRangeV2 {
      minVariantPrice {
        amount
      }
    }
  }
  query ShopifyProducts {
    allProducts: allShopifyProduct(sort: { fields: [title] }) {
      edges {
        node {
          ...ShopifyProductFields
          ...ProductTileFields
        }
      }
    }
    mainStandardPlanProducts: allShopifyCollection(
      filter: { title: { eq: "WebMainStandardPlanProducts" } }
    ) {
      nodes {
        title
        products {
          ...ShopifyProductFields
          ...ProductTileFields
        }
      }
    }
    mainTolettaBaseProducts: allShopifyCollection(
      filter: { title: { eq: "WebMainTolettaBaseProducts" } }
    ) {
      nodes {
        title
        products {
          ...ShopifyProductFields
          ...ProductTileFields
        }
      }
    }
    mainCatBaseProducts: allShopifyCollection(
      filter: { title: { eq: "WebMainCatBaseProducts" } }
    ) {
      nodes {
        title
        products {
          ...ShopifyProductFields
          ...ProductTileFields
        }
      }
    }
  }
`;

type PageProductsProps = {
  location: Location;
};

const PageProducts = ({ location }: PageProductsProps) => {
  const toast = useToast();

  const queriedProducts =
    useStaticQuery<GatsbyTypes.ShopifyProductsQuery>(query);

  React.useEffect(() => {
    async function init() {
      try {
        await resetPlan();
      } catch (err) {
        toast({
          title: <Text fontSize="md">プラン情報の取得に失敗しました</Text>,
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
      }
    }
    init();
  }, []);

  return (
    <>
      <Seo title="商品ページ" />
      <Title>トレッタ本体</Title>
      <ProductTabs queriedProducts={queriedProducts} />
      <ToCartButton />
    </>
  );
};

export default PageProducts;
