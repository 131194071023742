import { extendTheme } from '@chakra-ui/react';
const theme = {
  styles: {
    global: {
      body: {
        fontSize: '10px',
      },
    },
  },
  fonts: {
    body: '游ゴシック体, YuGothic, 游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, sans-serif',
  },
  colors: {
    brand: {
      // for colorScheme
      500: '#EE827D',
      // others
      pink: '#EE827D',
      yellow: '#FFD700',
      brown: 'CBA975',
      green: '#99C880',
      blue: '5586D0',
      lightgreen: '98EDE0',
      gray: '#707070',
      subtitle: '#708090',
      subtitle2: '#F9F7F6',
      background: '#F9F7F6',
      background2: '#F9F7F6',
    },
  },
  components: {
    Text: { baseStyle: { fontSize: 'xs', fontWeight: 600 } },
    Button: {
      baseStyle: {
        _focus: { boxShadow: 'none' },
      },
    },
    IconButton: {
      baseStyle: {
        _focus: { boxShadow: 'none' },
      },
    },
    Td: {
      baseStyle: {
        textAlign: 'center',
      },
    },
  },
};

export default extendTheme(theme);
