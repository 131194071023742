import * as React from 'react';

import Layout from '../components/Layout';
import PrivateRoute from '../components/PrivateRoute';
import PageHome from '../features/PageHome';

type PageProps = {
  location: Location;
};

const App = ({ location }: PageProps) => {
  return (
    <Layout location={location}>
      <PrivateRoute component={PageHome} location={location} path="/app" />
    </Layout>
  );
};

export default App;
