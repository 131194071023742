import * as React from 'react';
import {
  Box,
  Text,
  Accordion,
  Progress,
  Flex,
  Switch,
  Spacer,
} from '@chakra-ui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import OrderHistoryItem from './OrderHistoryItem';
import { getOrderHistoryService } from '../services';
import { OrderData } from '../types';
import { loadingState } from '../atoms/PageAtom';
import { reloadSelector } from '../selectors/PageStateSelector';

const OrderHistory = () => {
  const [orders, setOrders] = React.useState<OrderData[] | null>(null);
  const [include, setInclude] = React.useState<boolean>(false);
  const setIsLoading = useSetRecoilState(loadingState);
  const reload = useRecoilValue(reloadSelector);

  function handleIncludeCancelledOrder(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setInclude(event.target.checked);
  }

  React.useEffect(() => {
    let abortCtrl = new AbortController();
    async function init() {
      try {
        setIsLoading(true);
        let res = await getOrderHistoryService();
        console.log(res);
        setOrders(res.data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    init();
    return () => {
      abortCtrl.abort();
    };
  }, [reload]);

  return (
    <Box>
      {/* TODO: ページネーションの作成 */}
      <Flex justify="space-between" alignItems="center" mb={4}>
        <Text fontSize="xs" fontWeight={600} mb={4}>
          注文履歴
        </Text>
        <Spacer />
        <Text fontSize="xs" mr={2}>
          キャンセル済みを含む
        </Text>
        <Switch
          colorScheme="brand"
          size="md"
          onChange={handleIncludeCancelledOrder}
        />
      </Flex>
      {orders === null ? (
        <>
          <Progress size="xs" isIndeterminate colorScheme="brand" />
          <Text mt={2} textAlign="center">
            読み込み中・・・
          </Text>
        </>
      ) : orders.length === 0 ? (
        <Text mt={2} textAlign="center">
          まだ注文がありません
        </Text>
      ) : (
        <Accordion defaultIndex={[0]} allowMultiple>
          {include
            ? orders.map(order => (
                <OrderHistoryItem key={order.name} order={order} />
              ))
            : orders
                .filter(order => order.cancelled_at === null)
                .map(order => (
                  <OrderHistoryItem key={order.name} order={order} />
                ))}
        </Accordion>
      )}
    </Box>
  );
};

export default OrderHistory;
