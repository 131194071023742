import * as Sentry from '@sentry/gatsby';
import { ToastId, UseToastOptions } from '@chakra-ui/react';
import axios from 'axios';
import { errMsgConverter } from './converter';
import { checkMe } from '../services';

type ToastFunc = (options?: UseToastOptions | undefined) => ToastId | undefined;
export const toastErrors = (
  err: any,
  toast: ToastFunc,
  title?: string,
  description?: string
) => {
  checkMe();
  // 例外が設定されていればSentryへ通知を行う
  if (err) Sentry.captureException(err);
  if (title && description) {
    toast({
      title,
      description,
      status: 'error',
      isClosable: true,
      duration: 8000,
    });
  } else if (axios.isAxiosError(err)) {
    const showErrorMessage = errMsgConverter(err.response?.data.message);
    toast({
      title: 'エラーが発生しました',
      description: showErrorMessage,
      status: 'error',
      isClosable: true,
      duration: 8000,
    });
  } else if (err instanceof Error) {
    toast({
      title: 'エラーが発生しました',
      description:
        '不具合が発生しました。大変お手数ですがお問い合わせください。',
      status: 'error',
      isClosable: true,
      duration: 8000,
    });
  } else {
    toast({
      title: 'エラーが発生しました',
      description: '時間をおいて再度お試しください',
      status: 'error',
      isClosable: true,
      duration: 8000,
    });
  }
};
