import * as React from 'react';
import Layout from '../components/Layout';
import PrivateRoute from '../components/PrivateRoute';
import PageProducts from '../features/PageProducts';

type PageProps = {
  location: Location;
};

const ProductsPage = ({ location }: PageProps) => {
  return (
    <Layout location={location}>
      <PrivateRoute component={PageProducts} location={location} />
    </Layout>
  );
};

export default ProductsPage;
