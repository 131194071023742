import { atom } from 'recoil';
import { UserData } from '../types/index';

export const userDataState = atom<UserData>({
  key: 'userData',
  default: {
    address: '',
    building: '',
    city: '',
    email: '',
    family_id: 0,
    family_name: '',
    given_name: '',
    id: 0,
    is_owner_account: false,
    locale: '',
    middle_name: null,
    nick_name: null,
    password: null,
    phone: '',
    prefecture: '',
    zip_code: '',
  },
});
