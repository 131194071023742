import * as React from 'react';
import { navigate } from 'gatsby';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
} from 'react-hook-form';
import { useToast, Flex, Text, Divider, Button, Box } from '@chakra-ui/react';

import CustomButton from './CustomButton';
import { loadingState } from '../atoms/PageAtom';
import InputEmailController from './InputEmailController';
import InputPasswordController from './InputPasswordController';
import InputZipCodeController from './InputZipCodeController';
import InputPrefectureController from './InputPrefectureController';
import InputTextController from './InputTextController';
import InputAcceptsMarketingController from './InputAcceptsMarketingController';
import InputPhoneController from './InputPhoneController';
import {
  deleteCustomerService,
  getAcceptsMarketingService,
  getUserService,
  registerService,
  updateCustomerService,
  updateUserService,
} from '../services';
import { GetCouponResponseData, UserData } from '../types';
import { isLoggedIn, logout } from '../repository/tolettaApi';
import { userDataState } from '../atoms/UserAtom';
import { cartState } from '../atoms/CartAtom';

type FormRegisterProps = {
  couponCode: string;
  couponStatus: GetCouponResponseData | null;
};

const FormRegister = ({ couponCode, couponStatus }: FormRegisterProps) => {
  const [_, setUserData] = useRecoilState(userDataState);
  const setIsLoading = useSetRecoilState(loadingState);
  const [__, setCart] = useRecoilState(cartState);
  const [___, setUser] = React.useState<UserData | null>(null);
  const { control, formState, handleSubmit, getValues, setValue } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const toast = useToast();

  const onValid: SubmitHandler<any> = async values => {
    const {
      address,
      city,
      email,
      family_name,
      given_name,
      phone,
      prefecture,
      zip_code,
      building,
      password,
      acceptsMarketing,
    } = values;
    const body = {
      address,
      city,
      email,
      familyName: family_name,
      givenName: given_name,
      phone,
      prefecture,
      zipCode: zip_code,
      building,
      password,
      acceptsMarketing,
    };
    console.log('body: ', body);
    try {
      setIsLoading(true);
      let res = await registerService(body, couponCode);
      console.log(res);
      if (res.success) {
        console.log('Here: ', res);
        // もしも既にユーザがいてログイン成功ならばプロフィールを更新する
        if (res.messages[1] === 'user already exists, and login success.') {
          let userData = await getUserService();
          let newUserData: UserData = {
            id: userData.id,
            address: body.address,
            city: body.city,
            email: body.email,
            family_name: body.familyName,
            given_name: body.givenName,
            family_id: userData.family_id,
            is_owner_account: userData.is_owner_account,
            locale: userData.locale,
            middle_name: userData.middle_name,
            nick_name: userData.nick_name,
            phone: body.phone,
            prefecture: body.prefecture,
            zip_code: body.zipCode,
            building: body.building,
            connect_allow_model: userData.connect_allow_model,
          };
          await updateUserService(newUserData);
          setUserData(newUserData);
        }
        // 顧客新規作成の場合は何もしない、既存の場合はエラーが出るので更新処理をする
        if (res.messages[0].length > 0) {
          await updateCustomerService();
        }
        // クーポンがある場合はカートをクリアして商品ページへ
        if (
          couponCode !== 'dnQ2xMtZW7' &&
          couponCode !== 'TyWQ6xWp3a' &&
          couponStatus &&
          couponStatus.status.valid
        ) {
          setCart([]);
          navigate('/products/?tab=toletta');
        } else {
          navigate('/init-checkout/');
        }
        setIsLoading(false);
      } else {
        if (res.messages[0] === 'Phone has already been taken') {
          toast({
            title: <Text fontSize="md">会員登録に失敗しました</Text>,
            description: (
              <Text fontSize="sm">既に登録済みの電話番号です。</Text>
            ),
            status: 'error',
            isClosable: true,
          });
          throw new Error('会員登録に失敗しました');
        }
        if (res.messages[0] === 'Phone is invalid') {
          toast({
            title: <Text fontSize="md">会員登録に失敗しました</Text>,
            description: (
              <Text fontSize="sm">
                電話番号の形式が正しくありません。入力内容をご確認ください。
              </Text>
            ),
            status: 'error',
            isClosable: true,
          });
          throw new Error('会員登録に失敗しました');
        }
        if (res.messages[1] === 'user already exists, and login failed.') {
          toast({
            title: <Text fontSize="md">会員登録に失敗しました</Text>,
            description: (
              <Text fontSize="sm">既に登録済みのメールアドレスです。</Text>
            ),
            status: 'error',
            isClosable: true,
          });
          throw new Error('会員登録に失敗しました');
        }
        toast({
          title: <Text fontSize="md">会員登録に失敗しました</Text>,
          description: (
            <Text fontSize="sm">
              入力内容をご確認ください。解決しない場合はお問い合わせください。
            </Text>
          ),
          status: 'error',
          isClosable: true,
        });
        throw new Error('会員登録に失敗しました');
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const onInvalid: SubmitErrorHandler<FieldValues> = err => {
    console.log(err);
  };

  const reRegister = async () => {
    try {
      setIsLoading(true);
      let res = await deleteCustomerService();
      console.log(res);
      if (res.data.success) {
        logout();
        setUser(null);
      } else {
        if (
          res.data.errors.find(
            error =>
              error.message ===
              'Customer can’t be deleted because they have associated orders'
          )
        ) {
          logout();
          setUser(null);
        } else {
          throw new Error('顧客情報の削除に失敗しました');
        }
      }
    } catch (err) {
      console.log(err);
      toast({
        title: <Text fontSize="md">メールアドレスの解除に失敗しました</Text>,
        description: (
          <Text fontSize="sm">
            時間をおいてお試しください。解決しない場合はお問い合わせください。
          </Text>
        ),
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // 初期値設定
  React.useEffect(() => {
    let abortCtrl = new AbortController();
    async function init() {
      try {
        setIsLoading(true);
        let res = await getAcceptsMarketingService();
        setValue('acceptsMarketing', res, {
          shouldDirty: true,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    init();
    return () => {
      abortCtrl.abort();
    };
  }, []);

  // 一度登録後の情報変更時
  React.useEffect(() => {
    let abortCtrl = new AbortController();
    async function init() {
      if (!isLoggedIn()) return;
      try {
        setIsLoading(true);
        let userData = await getUserService();
        setUser(userData);
        setValue('zip_code', userData.zip_code, {
          shouldValidate: false,
          shouldDirty: false,
        });
        setValue('prefecture', userData.prefecture, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('city', userData.city, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('address', userData.address, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('building', userData.building, {
          shouldValidate: true,
          shouldDirty: true,
        });
        console.log(getValues());
        setValue('phone', userData.phone, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('family_name', userData.family_name, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('given_name', userData.given_name, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('email', userData.email, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('confirmEmail', userData.email, {
          shouldValidate: true,
          shouldDirty: true,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    init();
    return () => {
      abortCtrl.abort();
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onValid, onInvalid)}>
      <Text fontSize="sm" mb={4}>
        商品のお届け先を入力してください。
        <br />
        <Text as="span" color="red">
          *
        </Text>
        は入力が必須の項目です。
      </Text>
      <InputZipCodeController
        id="zip_code"
        control={control}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
        label="郵便番号"
      />
      <InputPrefectureController
        id="prefecture"
        control={control}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
      />
      <InputTextController
        id="city"
        control={control}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
        label="市区町村"
      />
      <InputTextController
        id="address"
        control={control}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
        label="丁目番地"
      />
      <InputTextController
        id="building"
        control={control}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
        label="建物名"
      />
      <InputPhoneController
        id="phone"
        control={control}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
        label="電話番号"
      />
      <InputTextController
        id="family_name"
        control={control}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
        label="お名前（姓）"
      />
      <InputTextController
        id="given_name"
        control={control}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
        label="お名前（名）"
      />
      <Divider my={4} />
      <Text fontSize="sm" mb={4}>
        {isLoggedIn()
          ? '確認のためパスワードの入力をお願いします。'
          : 'トレッタアプリご利用のため会員登録をお願いいたします。'}
      </Text>
      <InputEmailController
        id="email"
        control={control}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
      />
      {isLoggedIn() ? (
        <Box mb={2} textAlign="right">
          <Button variant="link" fontSize="xs" onClick={reRegister}>
            <u>メールアドレスを変更する</u>
          </Button>
        </Box>
      ) : (
        <InputEmailController
          id="confirmEmail"
          control={control}
          formState={formState}
          getValues={getValues}
          label="メールアドレスの確認"
          setValue={setValue}
        />
      )}
      <InputPasswordController
        id="password"
        control={control}
        formState={formState}
        getValues={getValues}
      />
      {isLoggedIn() ? null : (
        <InputPasswordController
          id="confirmPassword"
          control={control}
          formState={formState}
          getValues={getValues}
          label="パスワードの確認"
        />
      )}
      <InputAcceptsMarketingController
        id="acceptsMarketing"
        control={control}
      />
      <Text textAlign="center" mb={8}>
        会員登録には、
        <Text
          as="a"
          color="brand.pink"
          href="http://jp.tolettacat.com/pages/privacy"
          title="プライバシーポリシー"
          rel="noopener noreferrer"
          target="_blank"
        >
          <u>プライバシーポリシー</u>
        </Text>
        と<br />
        <Text
          as="a"
          color="brand.pink"
          href="https://jp.tolettacat.com/pages/terms_purchase"
          title="ご購入に関する規約"
          rel="noopener noreferrer"
          target="_blank"
        >
          <u>ご購入に関する規約</u>
        </Text>
        および
        <br />
        <Text
          as="a"
          color="brand.pink"
          href="http://jp.tolettacat.com/pages/terms"
          title="サービス利用規約"
          rel="noopener noreferrer"
          target="_blank"
        >
          <u>サービス利用規約</u>
        </Text>
        への同意が必要です。
      </Text>
      <Flex justify="space-between">
        <CustomButton
          bg="white"
          border="2px solid black"
          w="38%"
          onClick={() => navigate(-1)}
        >
          戻る
        </CustomButton>
        <CustomButton
          color="white"
          bg="brand.pink"
          w="60%"
          type="submit"
          disabled={!formState.isValid}
          _hover={{ bg: 'brand.pink' }}
        >
          同意して次へ
        </CustomButton>
      </Flex>
    </form>
  );
};

export default FormRegister;
