import * as React from 'react';
import { useSetRecoilState } from 'recoil';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
} from 'react-hook-form';
import {
  useToast,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
} from '@chakra-ui/react';

import { loadingState } from '../atoms/PageAtom';
import { changePasswordService } from '../services';
import InputPasswordController from './InputPasswordController';

type FormChangePasswordProps = {
  onClose: () => void;
  cancelRef: React.RefObject<HTMLButtonElement>;
};

const FormChangePassword = ({
  onClose,
  cancelRef,
}: FormChangePasswordProps) => {
  const { control, formState, handleSubmit, getValues } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const setIsLoading = useSetRecoilState(loadingState);
  const toast = useToast();

  const onValid: SubmitHandler<any> = async values => {
    try {
      setIsLoading(true);
      await changePasswordService(values.currentPassword, values.newPassword);
      onClose();
      toast({
        title: <Text fontSize="md">パスワードを変更しました"</Text>,
        status: 'success',
        isClosable: true,
      });
    } catch (err) {
      if (
        err instanceof Error &&
        err.message === 'Request failed with status code 401'
      ) {
        toast({
          title: <Text fontSize="md">パスワードの変更に失敗しました</Text>,
          description: (
            <Text fontSize="sm">
              現在のパスワードが異なります。正しいパスワードを入力していただくか、わからない場合は一度ログアウトしてリセットをお試しください。
            </Text>
          ),
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
        return;
      }
      toast({
        title: <Text fontSize="md">パスワードの変更に失敗しました</Text>,
        description: (
          <Text fontSize="sm">
            入力内容をご確認ください。解決しない場合はお問い合わせください。
          </Text>
        ),
        status: 'error',
        duration: 8000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onInvalid: SubmitErrorHandler<FieldValues> = err => {
    console.log(err);
  };

  return (
    <>
      <AlertDialogBody>
        <form onSubmit={handleSubmit(onValid, onInvalid)}>
          <InputPasswordController
            id="currentPassword"
            control={control}
            formState={formState}
            label="現在のパスワード"
            getValues={getValues}
          />
          <InputPasswordController
            id="newPassword"
            control={control}
            formState={formState}
            label="新しいパスワード"
            getValues={getValues}
          />
          <InputPasswordController
            id="confirmPassword"
            control={control}
            formState={formState}
            label="パスワードの確認"
            getValues={getValues}
          />
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              キャンセル
            </Button>
            <Button
              color="white"
              bg="brand.pink"
              type="submit"
              ml={3}
              disabled={!formState.isValid}
            >
              確定
            </Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogBody>
    </>
  );
};

export default FormChangePassword;
