import React from 'react';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Text, Box, Flex } from '@chakra-ui/react';

import PrimaryButton from '../components/PrimaryButton';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

type PageProps = {
  location: Location;
};

const NotesPage = ({ location }: PageProps) => {
  const notes = [
    {
      dt: 'Wi-Fi環境をCheck!',
      dd: 'ご自宅の固定Wi-Fi（無線LAN、2.4GHz）への常時接続が必要です。ポケットWi-FiやフリーWi-Fiはご利用いただけません。',
      btn: true,
      dd2: false,
    },
    {
      dt: 'トイレのサイズをCheck!',
      dd: 'サイズをご確認いただき、十分な設置スペースの確保をお願いいたします。',
      btn: false,
      dd2: false,
    },
    {
      dt: '設置環境をCheck!',
      dd: '電源があり、硬い床で、壁や物に接触しない場所に設置してください。ゲージ内やカーペットの上だと、センサーが安定せず正しく計測できないことがあります。',
      btn: false,
      dd2: false,
    },
    {
      dt: 'LEDライトは常時点灯',
      dd: '夜のトイレも記録するため、カメラについているLEDライトは常時点灯です。消灯や調光機能はありませんので、寝室などに置く場合はトイレカバー等をご検討ください。',
      btn: false,
      dd2: false,
    },
  ];

  return (
    <Layout location={location}>
      <Seo title="注意事項のご確認" />
      <Text fontSize="md" textAlign="center" mb={8}>
        こちらを読んでから
        <br />
        注文手続きへ進んでください。
      </Text>
      <Box justifyContent="center">
        {notes.map((note, i) => (
          <Box key={note.dt} maxW="300px" mx="auto">
            <Box
              position="relative"
              border="3px solid black"
              maxW="300px"
              mx="auto"
            >
              {i === 0 ? (
                <StaticImage
                  src="../images/dialog-wifi.jpg"
                  alt="設置場所について"
                  quality={60}
                  layout="fullWidth"
                />
              ) : i === 1 ? (
                <StaticImage
                  src="../images/dialog-size.jpg"
                  alt="LEDライト"
                  quality={60}
                  layout="fullWidth"
                />
              ) : i === 2 ? (
                <StaticImage
                  src="../images/dialog-place.jpg"
                  alt="Wi-Fi設定"
                  quality={60}
                  layout="fullWidth"
                />
              ) : (
                <StaticImage
                  src="../images/dialog-led.jpg"
                  alt="お支払い方法"
                  quality={60}
                  layout="fullWidth"
                />
              )}
              <Flex
                justify="center"
                align="center"
                position="absolute"
                top="-12%"
                left={0}
                right={0}
                margin="auto"
                color="white"
                bg="black"
                borderRadius="full"
                width="50px"
                height="50px"
                fontSize="lg"
              >
                <Box>{i + 1}</Box>
              </Flex>
            </Box>
            <Text mt={4} fontSize="lg">
              {note.dt}
            </Text>
            <Text mt={1} fontWeight={500}>
              {note.dd}
            </Text>
            {note.btn ? (
              <Text
                border="2px solid black"
                p={4}
                mt={8}
                mb={12}
                textAlign="center"
                borderRadius={40}
              >
                <Text
                  as="a"
                  href="https://jp.tolettacat.com/products/about-toletta#spec"
                  title="Wi-Fi環境詳細"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  詳しくはこちら
                </Text>
              </Text>
            ) : null}
            {note.dd2 ? (
              <Box mb={12}>
                <Text>注意事項</Text>
                <Text>・電源タップから1m以内であること</Text>
                <Text>・硬い床の上であること</Text>
                <Text>
                  ケージ内やカーペットの上だとセンサーが安定せず正しく測定できないことがあります。
                </Text>
              </Box>
            ) : (
              <Box mb={12} />
            )}
          </Box>
        ))}
      </Box>
      <PrimaryButton onClick={() => navigate('/')}>注文手続きへ</PrimaryButton>
    </Layout>
  );
};

export default NotesPage;
