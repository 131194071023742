import * as React from 'react';
import { navigate } from 'gatsby';
import { Flex, Text, Box } from '@chakra-ui/react';
import LinkTile from './LinkTile';
import { StaticImage } from 'gatsby-plugin-image';

const NavPanel = () => {
  function handleLinkTile(
    to:
      | '/products/'
      | '/profile/'
      | '/change-cc/'
  ) {
    navigate(to);
  }
  return (
    <>
      <LinkTile
        color="white"
        bg="brand.pink"
        mb={2}
        onClick={() => handleLinkTile('/products/')}
      >
        <Box w="15%" mx="auto" mb={2}>
          <StaticImage src="../images/mypage_toletta_add.png" alt="" />
        </Box>
        <Text color="white">トレッタを追加する</Text>
      </LinkTile>
      <Flex justify="space-between" mb={2}>
        <LinkTile
          color="black"
          w="50%"
          mr={1}
          onClick={() => handleLinkTile('/profile/')}
        >
          <Text>
            会員情報の
            <br />
            確認・変更
          </Text>
        </LinkTile>
        <LinkTile
          color="black"
          w="50%"
          ml={1}
          onClick={() => handleLinkTile('/change-cc/')}
        >
          <Text>
            カード情報の
            <br />
            確認・変更
          </Text>
        </LinkTile>
      </Flex>
    </>
  );
};

export default NavPanel;
