import * as React from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
} from '@chakra-ui/react';

import SecondaryButton from '../components/SecondaryButton';
import FormChangeProfile from '../components/FormChangeProfile';

const ProfileChangeInfoDialog = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <SecondaryButton onClick={() => setIsOpen(true)}>
        会員情報の変更
      </SecondaryButton>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent w="90%" my="auto">
            <AlertDialogHeader fontSize="sm" fontWeight="bold">
              会員情報の変更
            </AlertDialogHeader>
            <FormChangeProfile onClose={onClose} cancelRef={cancelRef} />
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ProfileChangeInfoDialog;
