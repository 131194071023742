import * as React from 'react';
import { Text } from '@chakra-ui/react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Title from '../components/Title/Title';

type PageProps = {
  location: Location;
};

const NotFoundPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <Seo title="404: Not found" />
    <Title>ページが見つかりません・・・</Title>
    <Text>
      <Link to="/">トップページへ</Link>
    </Text>
  </Layout>
);

export default NotFoundPage;
