import * as React from 'react';
import { navigate } from 'gatsby';
import { Flex, Button, Divider, Text } from '@chakra-ui/react';

import Title from '../components/Title/Title';
import Seo from '../components/Seo';
import UserName from '../components/UserName';
import { logout } from '../repository/tolettaApi';
import NavPanel from '../components/NavPanel';
import OrderHistory from './OrderHistory';
import { useRecoilState } from 'recoil';
import { cartState } from '../atoms/CartAtom';

const PageHome = () => {
  const [_, setCart] = useRecoilState(cartState);
  function handleLogout() {
    logout();
    setCart([]);
    navigate('/login/');
  }

  return (
    <>
      <Seo title="Home" />
      <Title>購入ページ</Title>
      <Flex justify="space-between" mb={4}>
        <UserName />
        <Button
          variant="link"
          color="brand.pink"
          fontSize="xs"
          onClick={handleLogout}
        >
          <u>ログアウト</u>
        </Button>
      </Flex>
      <NavPanel />
      {/* 追加の配送情報説明 */}
      {process.env.GATSBY_DELIVERY_ANNOUNCE ? (
        <Text fontSize="xs" color="brand.pink" p={2}>
          {process.env.GATSBY_DELIVERY_ANNOUNCE}
        </Text>
      ) : null}
      <Divider my={4} />
      <OrderHistory />
    </>
  );
};

export default PageHome;
