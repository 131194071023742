import * as React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

type BarProps = {
  bg: string;
};
const Bar = ({ bg }: BarProps) => (
  <Box h={1} w="8%" bg={bg} borderRadius="sm"></Box>
);

type StepperProps = {
  activeIndex: number;
};

const Stepper = ({ activeIndex }: StepperProps) => {
  let step: string[] = [
    'お客様\n情報の入力',
    'お支払い\n情報の入力',
    'ご注文\n完了',
  ];
  return (
    <Flex gridGap={4} mb={8} justify="space-between" alignItems="center">
      {step.map((s, i) => (
        <React.Fragment key={s}>
          <Flex direction="column" justify="space-between" alignItems="center">
            <Box
              w={8}
              h={8}
              borderRadius="50%"
              bg={
                activeIndex === i
                  ? 'white'
                  : activeIndex > i
                  ? 'brand.pink'
                  : 'brand.background2'
              }
              border={activeIndex > i ? 'none' : '4px solid'}
              borderColor={
                activeIndex === i
                  ? 'brand.pink'
                  : activeIndex < i
                  ? 'brand.gray'
                  : 'none'
              }
              mb={2}
            ></Box>
            <Text fontSize="xs" textAlign="center">
              {s.split(/(\n)/).map((item, i) => (
                <React.Fragment key={i}>
                  {item.match(/\n/) ? <br /> : item}
                </React.Fragment>
              ))}
            </Text>
          </Flex>
          {i === step.length - 1 ? null : (
            <Bar bg={activeIndex > i ? 'brand.pink' : 'brand.gray'} />
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default Stepper;
