import * as React from 'react';
import { navigate } from 'gatsby';
import { Box } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import PrimaryButton from './PrimaryButton';
import { cartState } from '../atoms/CartAtom';

const ToCartButton = () => {
  const [cart, _] = useRecoilState(cartState);

  return (
    <Box
      display={cart.length > 0 ? 'block' : 'none'}
      position="fixed"
      bottom={5}
      left={0}
      right={0}
      mx="auto"
      maxW="600px"
      zIndex="docked"
      w="90%"
    >
      <PrimaryButton onClick={() => navigate('/checkout/')} width="full">
        注文内容の確認へ
      </PrimaryButton>
    </Box>
  );
};

export default ToCartButton;
