import * as React from 'react';
import { useRecoilState } from 'recoil';
import {
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  FieldValues,
} from 'react-hook-form';
import {
  Text,
  useToast,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';

import { loadingState, reloadState } from '../atoms/PageAtom';
import InputPhoneController from './InputPhoneController';
import InputTextController from './InputTextController';
import InputPrefectureController from './InputPrefectureController';
import InputZipCodeController from './InputZipCodeController';
import { getUserService, updateUserService } from '../services';
import { UserData } from '../types';

type FormProfileChangeProps = {
  onClose: () => void;
  cancelRef: React.RefObject<HTMLButtonElement>;
};

const FormProfileChange = ({ onClose, cancelRef }: FormProfileChangeProps) => {
  const { control, formState, handleSubmit, getValues, setValue } = useForm({
    mode: 'all',
  });
  const [_, setIsLoading] = useRecoilState(loadingState);
  const [__, setReload] = useRecoilState(reloadState);
  const [user, setUser] = React.useState<UserData | null>(null);
  const toast = useToast();

  const onValid: SubmitHandler<any> = async values => {
    try {
      if (!user) throw new Error('ユーザーが見つかりません');
      setIsLoading(true);
      const newUser: UserData = {
        ...user,
        ...values,
      };
      await updateUserService(newUser);
      onClose();
      setReload(reload => !reload);
      toast({
        title: <Text fontSize="md">会員情報を変更しました</Text>,
        description: (
          <Text fontSize="sm">新しいデータを読み込んでいます・・・</Text>
        ),
        status: 'success',
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
      toast({
        title: <Text fontSize="md">会員情報の変更に失敗しました</Text>,
        description: (
          <Text fontSize="sm">
            入力内容をご確認ください。解決しない場合はお問い合わせください。
          </Text>
        ),
        status: 'error',
        duration: 8000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onInvalid: SubmitErrorHandler<FieldValues> = err => {
    console.log(err);
  };

  React.useEffect(() => {
    let abortCtrl = new AbortController();
    async function init() {
      try {
        setIsLoading(true);
        let userData = await getUserService();
        setUser(userData);
        setValue('zip_code', userData.zip_code, {
          shouldValidate: false,
          shouldDirty: false,
        });
        setValue('prefecture', userData.prefecture, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('city', userData.city, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('address', userData.address, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('building', userData.building, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('phone', userData.phone, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('family_name', userData.family_name, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('given_name', userData.given_name, {
          shouldValidate: true,
          shouldDirty: true,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    init();
    return () => {
      abortCtrl.abort();
    };
  }, []);

  return (
    <>
      <AlertDialogBody>
        <form onSubmit={handleSubmit(onValid, onInvalid)}>
          <InputZipCodeController
            id="zip_code"
            control={control}
            formState={formState}
            label="郵便番号"
            getValues={getValues}
            setValue={setValue}
          />
          <InputPrefectureController
            id="prefecture"
            control={control}
            formState={formState}
            label="都道府県"
            getValues={getValues}
            setValue={setValue}
          />
          <InputTextController
            id="city"
            control={control}
            formState={formState}
            label="市区町村"
            getValues={getValues}
            setValue={setValue}
          />
          <InputTextController
            id="address"
            control={control}
            formState={formState}
            label="丁目番地"
            getValues={getValues}
            setValue={setValue}
          />
          <InputTextController
            id="building"
            control={control}
            formState={formState}
            label="建物名"
            getValues={getValues}
            setValue={setValue}
          />
          <InputPhoneController
            id="phone"
            control={control}
            formState={formState}
            label="電話番号"
            getValues={getValues}
            setValue={setValue}
          />
          <InputTextController
            id="family_name"
            control={control}
            formState={formState}
            label="お名前(姓)"
            getValues={getValues}
            setValue={setValue}
          />
          <InputTextController
            id="given_name"
            control={control}
            formState={formState}
            label="お名前(名)"
            getValues={getValues}
            setValue={setValue}
          />
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              bg="white"
              border="1px solid lightgray"
            >
              キャンセル
            </Button>
            <Button
              color={formState.isValid ? 'white' : 'black'}
              bg={formState.isValid ? 'brand.pink' : 'lightgray'}
              type="submit"
              ml={3}
              isLoading={formState.isSubmitting}
              disabled={!formState.isValid}
            >
              確定
            </Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogBody>
    </>
  );
};

export default FormProfileChange;
