import * as React from 'react';
import { navigate } from 'gatsby';
import { Text, useToast, Box, chakra, Skeleton } from '@chakra-ui/react';
import * as queryString from 'query-string';

import Title from '../components/Title/Title';
import Seo from '../components/Seo';
import { completeDraftOrderService } from '../services';
import { useRecoilState } from 'recoil';
import { loadingState } from '../atoms/PageAtom';
import Stepper from '../components/Stepper';
import { StaticImage } from 'gatsby-plugin-image';
import SecondaryButton from '../components/SecondaryButton';
import { GtmService } from '../services/gtm.service';

type PageProps = {
  location: Location;
};

const PageInitThanks = ({ location }: PageProps) => {
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const [error, setError] = React.useState(false);
  const [msg, setMsg] = React.useState<string>(
    '時間をおいてお試しください。解決しない場合はお問い合わせください。'
  );
  const toast = useToast();

  let id: string;
  if (typeof window !== `undefined` && location) {
    const { draftOrderId } = queryString.parse(location?.search);
    if (typeof draftOrderId === 'string') id = draftOrderId;
  }

  // 下書き注文有効化処理
  React.useEffect(() => {
    async function completeDraftOrder(draftOrderId: string) {
      try {
        setIsLoading(true);
        let res = await completeDraftOrderService(draftOrderId);
        console.log(res);
        if (res.data.success) {
          console.log('OK');
          toast({
            title: <Text fontSize="md">注文が確定しました</Text>,
            status: 'success',
            isClosable: true,
          });
        } else {
          if (res.data.errors && res.data.errors.length > 0) {
            setError(true);
            setMsg(res.data.errors[0].message);
            toast({
              title: <Text fontSize="md">注文の確定に失敗しました</Text>,
              description: (
                <Text fontSize="sm">{res.data.errors[0].message}</Text>
              ),
              status: 'error',
              duration: 8000,
              isClosable: true,
            });
            return;
          }
          setError(true);
          throw Error('下書き注文の有効化に失敗しました');
        }
      } catch (err) {
        console.log(err);
        setError(true);
        if (err instanceof Error) {
          setMsg(err.message);
        }
        toast({
          title: <Text fontSize="md">注文の確定に失敗しました</Text>,
          description: <Text fontSize="sm">{msg}</Text>,
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
    if (!!id) completeDraftOrder(id);
  }, []);

  return (
    <>
      <Seo title="Thanks" />
      <Skeleton isLoaded={!isLoading}>
        <Stepper activeIndex={3} />
      </Skeleton>
      {error ? (
        <Box>
          <Text fontSize="lg">エラーが発生しました・・・</Text>
          <SecondaryButton mt={8} onClick={() => navigate('/app/')}>
            トップへ
          </SecondaryButton>
        </Box>
      ) : (
        <Skeleton isLoaded={!isLoading}>
          <Title>注文完了</Title>
          <Text as="h2" fontSize="lg" textAlign="center" mb={4}>
            ありがとうございました！
          </Text>
          <StaticImage src="../images/thankyou_pc.jpg" alt="" />
          <Text my={4}>
            注文が完了しました。
            <br />
            ご登録頂いた住所へトレッタ本体をお届けします。
          </Text>
          <Text mb={4}>
            トレッタのご利用にはスマートフォンアプリのダウンロードが必要です。
            <br />
            本体に同梱された案内に沿ってダウンロードし、ご登録頂いたメールアドレスとパスワードでログインしてください。
          </Text>
          <Box w="170px" mx="auto">
            <chakra.a
              href="https://play.google.com/store/apps/details?id=jp.toletta.app.basic&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Google Play で手に入れよう"
                src="https://play.google.com/intl/ja/badges/images/generic/ja_badge_web_generic.png"
              />
            </chakra.a>
          </Box>
          <Box textAlign="center">
            <a
              href="https://itunes.apple.com/jp/app/toletta-basic/id1453174795?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                width={150}
                src={'../images/ios-download.svg'}
                alt="App Store からダウンロード"
              />
            </a>
          </Box>
          <SecondaryButton mt={8} onClick={() => navigate('/app/')}>
            トップへ
          </SecondaryButton>
        </Skeleton>
      )}
    </>
  );
};

export default PageInitThanks;
