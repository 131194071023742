import * as React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const LinkTile: React.FC<BoxProps> = props => {
  return (
    <Box
      as="a"
      display="block"
      fontSize="xs"
      fontWeight={600}
      textAlign="center"
      border="2px solid"
      borderColor="brand.subtitle"
      borderRadius={8}
      p={4}
      {...props}
      _hover={{
        cursor: 'pointer',
      }}
    />
  );
};

export default LinkTile;
