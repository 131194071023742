import * as React from 'react';
import { navigate } from 'gatsby';
import {
  useToast,
  Text,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Title from '../components/Title/Title';
import { logout } from '../repository/tolettaApi';
import Stepper from '../components/Stepper';
import PrimaryButton from '../components/PrimaryButton';
import { remindMeService } from '../services';
import { useRecoilState } from 'recoil';
import { loadingState } from '../atoms/PageAtom';

type PageProps = {
  location: Location;
};

const DraftOrderedPage = ({ location }: PageProps) => {
  const [_, setIsLoading] = useRecoilState(loadingState);
  const toast = useToast();
  const reRegister = () => {
    logout();
    navigate('/');
  };

  const remind = async () => {
    try {
      setIsLoading(true);
      let res = await remindMeService();
      console.log(res);
      if (res.data.success) {
        toast({
          title: (
            <Text fontSize="md">
              {res.data.result.length > 0
                ? 'メールを送りました'
                : '注文がないようです・・・'}
            </Text>
          ),
          description:
            res.data.result.length > 0 ? null : (
              <Text fontSize="sm">
                ページトップから注文履歴をご確認ください。見つからない場合は大変恐れ入りますが再度ご注文をお願いします。
              </Text>
            ),
          status: res.data.result.length > 0 ? 'success' : 'error',
          duration: 8000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.log(err);
      toast({
        title: <Text fontSize="md">メールの送信に失敗しました</Text>,
        status: 'error',
        duration: 8000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout location={location}>
      <Seo title="注文メールの確認" />
      <Stepper activeIndex={2} />
      <Title>注文メールの確認</Title>
      <Text mb={4} color="brand.pink" fontSize="lg">
        ※注文はまだ完了しておりません。
      </Text>
      <Text mb={4}>
        確認のためメールを送信しました。
        <br />
        本文に記載されたリンクをクリックすると
        <br />
        注文が確定されます。
      </Text>
      <Text>
        もう一度メールを送るには
        <Button
          fontSize="xs"
          size="xs"
          variant="link"
          color="brand.pink"
          onClick={remind}
        >
          <u>こちら</u>
        </Button>
      </Text>
      <Accordion allowToggle mt={8}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Text flex="1" textAlign="left">
                メールが届かない場合
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              迷惑メールに分類されていないかご確認ください。
              <br />
              また、「hotmail」「live.jp」「outlook.jp」「
              icloud.jp」など一部のメールアドレスはご利用いただけないことがございます。
              <br />
              大変恐れ入りますが
              <Button
                color="brand.pink"
                variant="link"
                fontSize="xs"
                onClick={reRegister}
              >
                <u>こちら</u>
              </Button>
              から別のメールアドレスで再度ご登録をお願いします。
            </Text>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Text flex="1" textAlign="left">
                問題が解決しない場合
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              <Text
                as="a"
                href="https://toletta-cats.zendesk.com/hc/ja"
                title="トレッタサポート"
                rel="noopener noreferrer"
                target="_blank"
                color="brand.pink"
              >
                <u>こちら</u>
              </Text>
              のサポートサイトか、
              <br />
              support@toletta.jpまでお問い合わせください。
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <PrimaryButton onClick={() => navigate('/app')} mt={8}>
        トップへ
      </PrimaryButton>
    </Layout>
  );
};

export default DraftOrderedPage;
