import * as React from 'react';
import {
  Text,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { QueriedProduct } from '../types';
import QuantityAdjusterButton from '../components/QuantityAdjusterButton';
import { useRecoilState } from 'recoil';
import { cartState } from '../atoms/CartAtom';
import { storefrontId, getProductPrice } from '../assets/products';
import { Prices } from '../assets/prices';

type CheckoutQuantityChangeModalProps = {
  queriedProduct: QueriedProduct;
};

const CheckoutQuantityChangeModal = ({
  queriedProduct,
}: CheckoutQuantityChangeModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cart, _] = useRecoilState(cartState);

  const toletta = cart.find(i => i.storefrontId === storefrontId.toletta);

  return (
    <>
      <Button
        onClick={onOpen}
        variant="link"
        color="brand.pink"
        fontSize="small"
      >
        <u>台数を変更する</u>
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="90%">
          <ModalHeader>
            <Text fontSize="sm">台数を入力してください</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <QuantityAdjusterButton
              storefrontId={queriedProduct.variants?.[0].storefrontId}
              name={queriedProduct.title}
              price={getProductPrice(queriedProduct).toLocaleString()}
              min={1}
              showToast={false}
            />
            <Text
              textAlign="center"
              mt={2}
              mb={8}
              fontWeight={400}
              fontSize={13}
            >
              本体料金：¥
              {Math.round(
                Prices.TOLETTA_DEVICE *
                  Prices.TAX_RATE *
                  (toletta?.quantity || 0)
              ).toLocaleString()}
              <br />
              利用料金：¥
              {Math.round(
                Prices.TOLETTA_STANDARD_SUBSCRIPTION *
                  Prices.TAX_RATE *
                  (toletta?.quantity || 0)
              ).toLocaleString()}{' '}
              / 月
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CheckoutQuantityChangeModal;
