import * as React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Text } from "@chakra-ui/react";
import { getUserService } from "../services";
import { userDataState } from "../atoms/UserAtom";
import { loadingState } from "../atoms/PageAtom";

const UserName = () => {
  const [user, setUser] = useRecoilState(userDataState);
  const setIsLoading = useSetRecoilState(loadingState);

  React.useEffect(() => {
    async function init() {
      try {
        setIsLoading(true);
        let user = await getUserService();
        setUser(user);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    init();
  }, []);
  return (
    <Text
      fontSize="xs"
      fontWeight={600}
    >{`${user.family_name} ${user.given_name} 様`}</Text>
  );
};

export default UserName;
