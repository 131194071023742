import React from 'react';
import { navigate } from 'gatsby';
import { getUserToken, isLoggedIn, isSubscribeUser, setUserToken } from '../repository/tolettaApi';

const PrivateRoute = ({
  component: Component,
  location: Location,
  ...rest
}: any) => {
  if (
    typeof window !== `undefined` &&
    !isLoggedIn() &&
    location?.pathname !== `/login`
  ) {
    navigate('/login/');
    return null;
  }
  // ストレージにトークンが残っている場合、データ移行されていないかを確認する
  React.useEffect(() => {
    const checkSubscribeUser = async () => {
      const result = await isSubscribeUser(getUserToken());
      if (!result) {
        setUserToken('');
        navigate('/login/');
        return null;
      }
    }
    if (typeof window !== `undefined`) {
      checkSubscribeUser();
    }
  }, []);
  return (
    <Component
      {...rest}
      location={typeof window !== `undefined` ? location : undefined}
    />
  );
};
export default PrivateRoute;
