import * as React from 'react';
import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { GridItem, Text, Box, Divider } from '@chakra-ui/react';
import { QueriedProduct } from '../types';
import { getProductPrice } from '../assets/products';
import QuantityAdjusterButton from '../components/QuantityAdjusterButton';
import { Prices } from '../assets/prices';

type PorductCardProps = {
  product: QueriedProduct;
};

const ProductCard = ({ product }: PorductCardProps) => {
  const imageData = getImage(
    product.featuredImage.localFile
  ) as IGatsbyImageData;

  return (
    <GridItem
      boxShadow="md"
      border="1px solid"
      borderColor="brand.background"
      borderRadius="md"
      p={4}
      w="100%"
      textAlign="center"
    >
      <Box
        display="block"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        w="200px"
        h="200px"
        mx="auto"
        mb={4}
      >
        <GatsbyImage
          alt={product.title}
          image={imageData}
          style={{ width: '100%', height: '100%' }}
          imgStyle={{ objectFit: 'cover' }}
        />
      </Box>
      <Text>{product.title}</Text>
      <Text textAlign="center">
        ¥&nbsp;
        <Text as="span" color="brand.pink" fontSize="lg">
          {getProductPrice(product).toLocaleString()}
        </Text>
      </Text>
      <Text color="brand.subtitle" fontSize="xs">
        （税込¥
        {Math.round(
          getProductPrice(product) * Prices.TAX_RATE
        ).toLocaleString()}
        )
      </Text>
      <Divider my={4} />
      <Text
        fontSize="xs"
        color='brand.subtitle'
        mb={4}
      >
        {product.description}
      </Text>
      {/* 追加の配送情報説明 */}
      {process.env.GATSBY_DELIVERY_ANNOUNCE ? (
        <Text fontSize="xs" color="brand.pink" mb={4}>
          {process.env.GATSBY_DELIVERY_ANNOUNCE}
        </Text>
      ) : null}
      <QuantityAdjusterButton
        storefrontId={product.variants?.[0].storefrontId}
        name={product.title}
        price={getProductPrice(product).toString()}
      />
    </GridItem>
  );
};

export default ProductCard;
