import * as React from 'react';
import {
  Text,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { OrderData } from '../types';
import { dayFormat } from '../utils/days';
import OrderCancelButton from './OrderCancelButton';
import PriceBoard from '../components/PriceBoard';
import CustomButton from '../components/CustomButton';

type OrderHistoryItemProps = {
  order: OrderData;
};

const OrderHistoryItem = ({ order }: OrderHistoryItemProps) => {
  return (
    <AccordionItem
      borderRadius="md"
      border="1px solid gray"
      boxShadow="1px 1px 2px gray"
      mb={2}
    >
      <h2>
        <AccordionButton
          bg="brand.background"
          borderRadius="md"
          _expanded={{
            borderRadius: 'md',
          }}
        >
          <Text fontSize="xx-small" flex="1" textAlign="left">
            {order.name}
          </Text>
          <Text fontSize="xx-small" flex="4" textAlign="right" mr={2}>
            注文日：{dayFormat(order.created_at)}
          </Text>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel py={4}>
        <Text
          fontSize="xs"
          fontWeight={600}
          borderBottom="2px solid black"
          pb={2}
          mb={2}
        >
          ご購入品
        </Text>
        <PriceBoard order={order} adjustQty={false} />
        {order.cancelled_at !== null ? (
          <CustomButton
            disabled
            p={6}
            mt={4}
            bg="white"
            border="1px solid black"
          >
            {dayFormat(order.cancelled_at)}に<br />
            キャンセル済み
          </CustomButton>
        ) : order.fulfillment_status === 'partial' ||
          order.fulfillment_status === 'fulfilled' ? (
          <CustomButton
            disabled
            p={6}
            mt={4}
            bg="white"
            border="1px solid black"
          >
            一部か全ての商品について
            <br />
            発送済みです
          </CustomButton>
        ) : (
          <OrderCancelButton order={order} />
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default OrderHistoryItem;
