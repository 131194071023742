import * as React from 'react';
import { useToast, Text } from '@chakra-ui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { loadingState, reloadState } from '../atoms/PageAtom';
import CustomAlertDialog from '../components/CustomAlertDialog';
import { cancelOrderService, isCancellableOrderService } from '../services';
import { OrderData } from '../types';
import { reloadSelector } from '../selectors/PageStateSelector';

type OrderCancelProps = {
  order: OrderData;
};

const OrderCancelButton = ({ order }: OrderCancelProps) => {
  const reload = useRecoilValue(reloadSelector);
  const setIsLoading = useSetRecoilState(loadingState);
  const setReload = useSetRecoilState(reloadState);
  const toast = useToast();

  async function handleCancel() {
    try {
      setIsLoading(true);
      let isCanbellable = await isCancellableOrderService(order);
      if (!isCanbellable) {
        toast({
          title: <Text fontSize="md">キャンセルに失敗しました</Text>,
          description: (
            <Text fontSize="sm">
              発送の準備に入っております。詳しくはお問い合わせください。
            </Text>
          ),
          status: 'error',
          isClosable: true,
        });
        return;
      }
      let res = await cancelOrderService(order);
      console.log(res);
      toast({
        title: <Text fontSize="md">キャンセルが完了しました</Text>,
        status: 'success',
        isClosable: true,
      });
      setReload(!reload);
    } catch (err) {
      console.log(err);
      toast({
        title: <Text fontSize="md">キャンセルに失敗しました</Text>,
        description: (
          <Text fontSize="sm">
            発送準備中の場合キャンセルができない場合がございます。詳しくはお問い合わせください。
          </Text>
        ),
        status: 'error',
        duration: 8000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <CustomAlertDialog
      buttonText="キャンセル"
      title="注文のキャンセル"
      okText="はい"
      cancelText="いいえ"
      submit={handleCancel}
    >
      <Text>注文のキャンセルを行います。よろしいですか？</Text>
    </CustomAlertDialog>
  );
};

export default OrderCancelButton;
