import * as React from 'react';

import PrivateRoute from '../components/PrivateRoute';
import PageInitCheckout from '../features/PageInitCheckout';

type PageProps = {
  location: Location;
};

const InitCheckoutPage = ({ location }: PageProps) => {
  return <PrivateRoute component={PageInitCheckout} location={location} />;
};

export default InitCheckoutPage;
