import * as React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Title from '../components/Title/Title';
import Seo from '../components/Seo';
import FormChangeCC from '../components/FormChangeCC';

const PageChangeCC = () => {
  const stripePromise = React.useMemo(
    () => loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY as string),
    []
  );

  return (
    <>
      <Seo title="カード情報の確認・変更" />
      <Title>カード情報の確認・変更</Title>
      <Elements stripe={stripePromise}>
        <FormChangeCC />
      </Elements>
    </>
  );
};

export default PageChangeCC;
