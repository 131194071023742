import * as React from 'react';
import Layout from '../components/Layout';
import PrivateRoute from '../components/PrivateRoute';
import PageProfile from '../features/PageProfile';

type PageProps = {
  location: Location;
};

const ProfilePage = ({ location }: PageProps) => {
  return (
    <Layout location={location}>
      <PrivateRoute component={PageProfile} location={location} />
    </Layout>
  );
};

export default ProfilePage;
