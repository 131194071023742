import * as React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

const CustomButton: React.FC<ButtonProps> = props => {
  return (
    <Button borderRadius={34} w="full" fontSize="lg" {...props} size="lg" />
  );
};

export default CustomButton;
