import * as React from 'react';

import Title from '../components/Title/Title';
import Seo from '../components/Seo';
import {
  List,
  ListItem,
  Text,
  Divider,
  Skeleton,
  Box,
  Spacer,
  Switch,
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { loadingState, reloadState } from '../atoms/PageAtom';
import {
  changeAcceptsMarketingService,
  getAcceptsMarketingService,
  getCardExpire,
  getCardInfoService,
  getUserService,
} from '../services';
import { ShowCCData, UserData } from '../types';
import ProfilePasswordChangeDialog from './ProfilePasswordChangeDialog';
import ProfileInfoChangeDialog from './ProfileInfoChangeDialog';

const PageProfile = () => {
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const [reload, _] = useRecoilState(reloadState);
  const [user, setUser] = React.useState<UserData | null>(null);
  const [cc, setCC] = React.useState<ShowCCData | null>(null);
  const [accepts, setAccepts] = React.useState<boolean>(false);

  React.useEffect(() => {
    let abortCtrl = new AbortController();
    async function init() {
      try {
        setIsLoading(true);
        let userData = await getUserService();
        let card = await getCardInfoService();
        // let acceptsMarketing = await getAcceptsMarketingService();
        setUser(userData);
        setCC(card);
        // setAccepts(acceptsMarketing);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    init();
    return () => {
      abortCtrl.abort();
    };
  }, [reload]);

  const profile = [
    { name: 'メールアドレス', value: `${user?.email || ''}` },
    { name: 'パスワード', value: `******` },
    {
      name: 'ユーザ名',
      value: `${user?.family_name || ''} ${user?.given_name || ''}`,
    },
    { name: '郵便番号', value: `${user?.zip_code || ''}` },
    {
      name: '住所',
      value: `${user?.prefecture || ''} ${user?.city || ''} ${
        user?.address || ''
      } ${user?.building || ''}`,
    },
    { name: '電話番号', value: `${user?.phone || ''}` },
    {
      name: 'お支払い情報',
      value: cc
        ? `${cc.brand} 末尾${cc.last4} | 有効期限 ${getCardExpire(cc.expire)}`
        : 'カード情報が登録されていません',
    },
    // {
    //   name: 'トレッタのお知らせを受け取る',
    //   value: `${accepts ? 'はい' : 'いいえ'}`,
    // },
  ];

  async function handleChangeAcceptsMarketing() {
    try {
      setIsLoading(true);
      let flag = await changeAcceptsMarketingService();
      setAccepts(flag);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Seo title="会員情報" />
      <Title>会員情報</Title>
      <List mb={4}>
        {profile.map(p => (
          <Skeleton isLoaded={!isLoading} key={p.name}>
            <ListItem display="flex" alignItems="center">
              <Box>
                <Text fontSize="sm">{p.name}</Text>
                <Text color="brand.subtitle">{p.value || ''}</Text>
              </Box>
              <Spacer />
              {p.name === 'トレッタのお知らせを受け取る' ? (
                <Box>
                  <Switch
                    defaultChecked={accepts}
                    colorScheme="brand"
                    onChange={handleChangeAcceptsMarketing}
                  />
                </Box>
              ) : null}
            </ListItem>
            <Divider my={2} />
          </Skeleton>
        ))}
      </List>
      <ProfilePasswordChangeDialog />
      <Box h={2} />
      <ProfileInfoChangeDialog />
    </>
  );
};

export default PageProfile;
