import * as React from 'react';
import { Link } from 'gatsby';
import { Text, Stack } from '@chakra-ui/react';

import Title from '../components/Title/Title';
import Seo from '../components/Seo';
import { StaticImage } from 'gatsby-plugin-image';

const PageThanks = () => {
  return (
    <>
      <Seo title="購入完了" />
      <Title>購入完了</Title>
      <Stack>
        <Text as="h2" fontSize="lg" textAlign="center" mb={4}>
          ありがとうございました！
        </Text>
        <StaticImage src="../images/thankyou_pc.webp" alt="" />
        <Text textAlign="center">
          ご登録いただいている住所へ
          <br />
          商品をお届けします。
          <br />
        </Text>
        <Text mt={8} textAlign="center">
          <Link to="/app">
            <Text as="span" color="brand.pink">
              <u>トップへ</u>
            </Text>
          </Link>
        </Text>
      </Stack>
    </>
  );
};

export default PageThanks;
