import * as React from 'react';
import * as queryString from 'query-string';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Title from '../components/Title/Title';
import FormRegister from '../components/FormRegister';
import Stepper from '../components/Stepper';
import { checkCouponStatus } from '../services';
import { toastErrors } from '../utils/errors';
import { useToast } from '@chakra-ui/react';
import { GetCouponResponseData } from '../types';

type PageProps = {
  location: Location;
};

const RegisterPage = ({ location }: PageProps) => {
  const toast = useToast();
  const [couponStatus, setCouponStatus] =
    React.useState<GetCouponResponseData | null>(null);

  // クーポンコードの読み取り
  let couponCode = '';
  if (typeof window !== `undefined` && location) {
    const { code } = queryString.parse(location?.search);
    if (typeof code === 'string') couponCode = code;
  }

  // クーポンコードの確認
  React.useEffect(() => {
    let abortCtrl = new AbortController();
    async function init() {
      if (couponCode) {
        try {
          const res = await checkCouponStatus(couponCode);
          setCouponStatus(res.data);
        } catch (err) {
          toastErrors(err, toast);
        }
      }
    }
    init();
    return () => {
      abortCtrl.abort();
    };
  }, []);

  return (
    <Layout location={location}>
      <Seo title="注文情報の入力" />
      {couponCode !== 'dnQ2xMtZW7' &&
      couponCode !== 'TyWQ6xWp3a' &&
      couponStatus &&
      couponStatus.status.valid ? null : (
        <Stepper activeIndex={0} />
      )}
      <Title>注文情報の入力</Title>
      <FormRegister couponCode={couponCode} couponStatus={couponStatus} />
    </Layout>
  );
};

export default RegisterPage;
