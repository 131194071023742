import * as React from 'react';
import { UseFormGetValues } from 'react-hook-form';
import {
  FormControl,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { Control, FormState, Controller, FieldValues } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import CustomFormLabel from './CustomFormLabel';
import CustomInput from './CustomInput';
import { isValidPassword } from '../utils/validations';

type InputPasswordControllerProps = {
  id: string;
  control: Control<FieldValues, object>;
  formState: FormState<FieldValues>;
  label?: string;
  getValues: UseFormGetValues<FieldValues>;
  showHelperText?: boolean;
  isRequired?: boolean;
  isValidationRequired?: boolean;
};

const InputPasswordController = ({
  id,
  control,
  formState,
  getValues,
  label = 'パスワード',
  showHelperText = true,
  isRequired = true,
  isValidationRequired = true,
}: InputPasswordControllerProps) => {
  const [show, setShow] = React.useState<boolean>(false);

  function handleShowPassword() {
    setShow(show => !show);
  }

  return (
    <Controller
      name={id}
      control={control}
      rules={{
        required: '入力が必須の項目です',
        validate: value => {
          if (
            id !== 'currentPassword' &&
            isValidationRequired &&
            !isValidPassword(value)
          ) {
            return 'パスワードが条件を満たしていません';
          }
          if (id === 'newPassword') {
            let currentPassword = getValues('currentPassword');
            return (
              currentPassword !== value || '同じパスワードに変更はできません'
            );
          }
          if (id === 'confirmPassword') {
            if (getValues('password')) {
              let password = getValues('password');
              return password === value || 'パスワードが異なります';
            } else {
              let newPassword = getValues('newPassword');
              return newPassword === value || 'パスワードが異なります';
            }
          }
          return true;
        },
      }}
      render={({ field }) => (
        <FormControl {...field} isRequired={isRequired}>
          <CustomFormLabel fontSize="sm">{label}</CustomFormLabel>
          <Text
            fontSize="xs"
            color={!!formState.errors[id] ? 'brand.pink' : 'brand.subtitle'}
            mb={1}
          >
            {!!formState.errors[id]
              ? formState.errors[id].message
              : id === 'confirmPassword'
              ? '上と同じパスワードを入力してください'
              : showHelperText
              ? '半角英数字6文字以上、数字と文字両方を含むものにしてください'
              : ''}
          </Text>
          <InputGroup>
            <CustomInput
              id={id}
              type={show ? 'text' : 'password'}
              isInvalid={!!formState.errors[id]}
            />
            <InputRightElement
              children={
                <IconButton
                  variant="unstyled"
                  aria-label="show password"
                  icon={show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                  onClick={handleShowPassword}
                />
              }
            ></InputRightElement>
          </InputGroup>
        </FormControl>
      )}
    />
  );
};

export default InputPasswordController;
