import * as React from "react";
import { Grid } from "@chakra-ui/react";
import { QueriedProduct } from "../types";
import ProductCard from "./ProductCard";

type ProductsGridProps = {
  // TODO: 型定義をつける
  products: any;
};

const ProductsGrid = ({ products }: ProductsGridProps) => {
  // TODO: 取り急ぎasをつけている
  let productList = products as QueriedProduct[];

  return (
    <Grid
      gap={4}
      templateRows={{
        base: `repeat(${productList.length}, 1fr)`,
        md: `repeat(${productList.length / 2}, 1fr)`,
      }}
      templateColumns={{
        base: `repeat(1, 1fr)`,
        md: `repeat(${productList.length > 1 ? 2 : 1}, 1fr)`,
      }}
    >
      {productList.map(product => (
        <ProductCard key={product.shopifyId} product={product} />
      ))}
    </Grid>
  );
};

export default ProductsGrid;
