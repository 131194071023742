import * as React from 'react';
import { Link } from 'gatsby';
import { Text } from '@chakra-ui/react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Title from '../components/Title/Title';
import FormResetPassword from '../components/FormResetPassword';

type PageProps = {
  location: Location;
};

const ResetPassword = ({ location }: PageProps) => {
  return (
    <Layout location={location}>
      <Seo title="パスワード再発行" />
      <Title>パスワード再発行</Title>
      <Text fontSize="xs" mb={4}>
        メールアドレスを以下にご入力ください。
        <br />
        パスワードを再発行の上、メールにてご連絡いたします。
      </Text>
      <FormResetPassword />
      <Text fontSize="xs" fontWeight={600} mt={4} mb={4} color="brand.subtitle">
        ログインは
        <Link to="/login">
          <Text as="span" color="brand.pink">
            <u>こちら</u>
          </Text>
        </Link>
      </Text>
    </Layout>
  );
};

export default ResetPassword;
