import * as React from 'react';
import {
  FormControl,
  Text,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  Control,
  FormState,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';

import CustomFormLabel from './CustomFormLabel';
import CustomInput from './CustomInput';
import IconCheck from './Icons/IconCheck';
import { isLoggedIn } from '../repository/tolettaApi';

type InputEmailControllerProps = {
  id: string;
  control: Control<FieldValues, object>;
  formState: FormState<FieldValues>;
  label?: string;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  showHelperText?: boolean;
  isRequired?: boolean;
};

const InputEmailController = ({
  id,
  control,
  formState,
  label = 'メールアドレス',
  getValues,
  setValue,
  showHelperText = true,
  isRequired = true,
}: InputEmailControllerProps) => {
  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(id, event.target.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }
  return (
    <Controller
      name={id}
      control={control}
      rules={{
        required: '入力が必須の項目です',
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: 'メールアドレスを入力してください',
        },
        validate: async value => {
          if (id === 'confirmEmail') {
            let email = getValues('email');
            return email === value || 'メールアドレスが一致していません';
          }
          return true;
        },
      }}
      render={({ field }) => (
        <FormControl {...field} isRequired={isRequired}>
          <CustomFormLabel fontSize="sm">{label}</CustomFormLabel>
          <Text
            fontSize="xs"
            color={!!formState.errors[id] ? 'brand.pink' : 'brand.subtitle'}
            mb={1}
          >
            {!!formState.errors[id]
              ? formState.errors[id].message
              : id === 'confirmEmail'
              ? '上と同じメールアドレスを入力してください'
              : showHelperText
              ? '「hotmail」「live.jp」「outlook.jp」「icloud.jp」のメールアドレスはご利用いただけません'
              : ''}
          </Text>
          <InputGroup>
            <CustomInput
              id={id}
              type="email"
              isInvalid={!!formState.errors[id]}
              value={getValues()[id] || ''}
              onChange={handleOnChange}
              isReadOnly={isLoggedIn()}
              color={isLoggedIn() ? 'brand.subtitle' : 'black'}
              bg={isLoggedIn() ? 'brand.background' : 'white'}
            />
            {formState.dirtyFields[id] && !formState.errors[id] ? (
              <InputRightElement children={<IconCheck color="green.500" />} />
            ) : null}
          </InputGroup>
        </FormControl>
      )}
    />
  );
};

export default InputEmailController;
